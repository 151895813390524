<template>
  <BaseDrawer
    no-padding
    height-auto-only
    no-toolbar
    no-bottom-rounding
    class="scrollbar-hide"
    close-label="settings"
    @close="$emit('close')"
  >
    <template #content>
      <div
        class="w-full flex flex-col"
        style="height: calc(100vh - 80px)"
      >
        <!-- Toolbar -->
        <div class="w-full flex items-center h-14 justify-between px-4 py-3 border-b border-border-normal">
          <!-- Label -->
          <div class="flex items-center p-1.5 gap-1">
            <SettingsIcon class="text-icon-muted" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Settings
            </BaseText>
          </div>
          <div class="flex items-center gap-2">
            <!-- Save Changes - conditionally shown based on hasUnsavedChanges -->
            <button
              class="black-btn-primary rounded-md text-white flex items-center gap-1 px-2 py-1.5"
              :class="{'opacity-90 cursor-not-allowed': !hasUnsavedChanges}"
              :disabled="!hasUnsavedChanges"
              @click="handleSaveChanges"
            >
              <BaseText
                type="label"
                size="sm"
              >
                Save Changes
              </BaseText>
            </button>

            <!-- Exit -->
            <button
              class="p-1.5"
              @click="$emit('close')"
            >
              <TagRemoveIcon stroke="#5E6678" />
            </button>
          </div>
        </div>
        <div class="content-grid flex-grow h-full min-h-0">
          <!-- Sidebar navigation -->
          <div class="h-full flex flex-col justify-between border-r border-border-normal p-4">
            <!-- Navigation Options -->
            <div class="w-full flex flex-col gap-1">
              <BaseText
                type="heading"
                size="overline"
                class="text-text-subdued uppercase mb-2"
              >
                Account & Team
              </BaseText>
              <button
                v-for="(option, index) in sidebarNavOptions"
                :key="`nav-option-${index}`"
                class="group nav-btn flex items-center gap-2 p-1.5 rounded-lg relative"
                :disabled="preventNav(option)"
                :class="{'active': getSettingsDrawerViewMode === option.viewMode}"
                @click="handleViewModeNavigation(option.viewMode)"
              >
                <component
                  :is="option.icon"
                  class="transition-colors duration-100"
                  :class="getSettingsDrawerViewMode === option.viewMode ? 'text-icon-muted' : 'text-icon-normal group-hover:text-icon-muted'"
                />
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ option.name }}
                </BaseText>
                <!-- Disable icon -->
                <div
                  v-if="preventNav(option)"
                  class="absolute my-auto top-0 bottom-0 right-1"
                >
                  <div class="flex items-center gap-2 relative w-full h-full">
                    <!-- Admin tooltip -->
                    <BaseText
                      size="xs"
                      class="opacity-0 transition-all left-0 right-0 mx-auto -top-3 w-max tooltip-text"
                    >
                      {{ option?.blockedText || 'Admin only' }}
                    </BaseText>
                    <LockedIcon />
                  </div>
                </div>
              </button>

              <!-- Lens Settings -->
              <div class="w-full flex flex-col gap-1">
                <BaseText
                  type="heading"
                  size="overline"
                  class="text-text-subdued uppercase mb-2 mt-3"
                >
                  Lens Settings
                </BaseText>
                <button
                  v-for="(option, index) in getLenses"
                  :key="`nav-option-${index}`"
                  class="group nav-btn flex items-center gap-2 p-1.5 rounded-lg relative"
                  :disabled="preventNav(option)"
                  :class="{'active': getSettingsDrawerViewMode === 'lens' && getCurrentRouteId == option.id}"
                  @click="handleLensSettingsNavigation(option.id)"
                >
                  <div class="w-5 h-5 rounded-md flex items-center justify-center">
                    <img
                      v-if="option.avatar"
                      :src="option.avatar"
                      class="w-5 h-5 object-cover rounded-md"
                    >
                    <div
                      v-else
                      class="w-5 h-5 flex items-center justify-center rounded-md bg-neutral-400"
                    >
                      <span
                        class="text-background-hover text-label-xs font-medium"
                        style="font-size: 10px;"
                      >
                        {{ getInitials(option.name) }}
                      </span>
                    </div>
                  </div>
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted truncate"
                  >
                    {{ option.name }}
                  </BaseText>
                </button>

                <!-- Lens Empty State -->
                <div
                  v-if="getLenses.length === 0"
                  class="w-full flex flex-col text-center items-center justify-center gap-1 bg-background-disabled rounded-lg px-2 pt-2 pb-1"
                >
                  <div class="flex flex-col items-center justify-center gap-1 px-2">
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-text-muted"
                    >
                      No Lens Accounts
                    </BaseText>
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-text-normal"
                    >
                      Connect an ad account to build performance creative reports.
                    </BaseText>
                  </div>
                  <BaseButton
                    outlined
                    class="text-text-muted w-full mt-2"
                    @click="handleCreateLens"
                  >
                    <div class="flex items-center gap-1">
                      <LensIcon
                        class="text-icon-muted transition-colors duration-100 group-hover:text-icon-normal flex-shrink-0"
                      />
                      <BaseText
                        type="label"
                        size="sm"
                        class="text-text-muted"
                      >
                        Create Lens
                      </BaseText>
                    </div>
                  </BaseButton>
                </div>
              </div>
            </div>

            <!-- Logout Button -->
            <button
              class="group logout-btn flex items-center justify-center gap-1.5 px-2 py-1.5 rounded-md"
              @click="handleLogout"
            >
              <LogoutIcon class="text-icon-normal transition-colors duration-100 group-hover:text-secondary-red-200" />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted transition-colors duration-100 group-hover:text-secondary-red-200"
              >
                Log Out
              </BaseText>
            </button>
          </div>
          <!-- Content View -->
          <div class="w-full h-full overflow-y-auto">
            <MyAccountSettings v-if="getSettingsDrawerViewMode === 'account'" />
            <TeamSettings v-if="getSettingsDrawerViewMode === 'team'" />
            <WhiteLabelSettings
              v-if="getSettingsDrawerViewMode === 'whitelabel'"
              :current-team="getTeam"
              :current-user="getUser"
            />
            <ExpertProfileSettings
              v-if="getSettingsDrawerViewMode === 'expert'"
              :current-user="getUser"
            />
            <AffiliateReferralSettings v-if="getSettingsDrawerViewMode === 'referral'" />
            <PlanSettings
              v-if="getSettingsDrawerViewMode === 'plan'"
              @close="$emit('close')"
            />
            <PlanSettings
              v-if="getSettingsDrawerViewMode === 'plan'"
              @close="$emit('close')"
            />
            <BillingSettings v-if="getSettingsDrawerViewMode === 'billing'" />
            <IntegrationSettings v-if="getSettingsDrawerViewMode === 'integrations'" />
            <NotificationSettings v-if="getSettingsDrawerViewMode === 'notifications'" />
            <LensSettings v-if="getSettingsDrawerViewMode === 'lens'" />
          </div>
        </div>
      </div>
    </template>
  </BaseDrawer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

// VIEW COMPONENTS
import MyAccountSettings from './MyAccountSettings.vue'
import TeamSettings from './team/TeamSettings.vue'
import WhiteLabelSettings from './WhiteLabelSettings.vue'
import ExpertProfileSettings from './ExpertProfileSettings.vue'
import AffiliateReferralSettings from './AffiliateReferralSettings.vue'
import PlanSettings from './PlanSettings.vue'
import BillingSettings from './BillingSettings.vue'
import IntegrationSettings from './integration/IntegrationSettings.vue'
import NotificationSettings from './NotificationSettings.vue'
import LensSettings from './LensSettings.vue'

// ICONS
import LensIcon from '../globals/Icons/SidebarIcons/LensIcon.vue'
import SettingsIcon from '../globals/Icons/SettingsIcons/SettingsIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import LogoutIcon from '../globals/Icons/LogoutIcon.vue'
import LockedIcon from '../globals/Icons/LockedIcon.vue'
import * as ViewIcons from '../globals/Icons/SettingsIcons'

export default {
  name: 'SettingsDrawer',
  components: {
    MyAccountSettings,
    TeamSettings,
    WhiteLabelSettings,
    ExpertProfileSettings,
    AffiliateReferralSettings,
    PlanSettings,
    BillingSettings,
    IntegrationSettings,
    LensSettings,
    NotificationSettings,
    SettingsIcon,
    TagRemoveIcon,
    LogoutIcon,
    LockedIcon,
    LensIcon
  },
  data () {
    const sidebarNavOptions = [
      { name: 'My Account', icon: ViewIcons.MyAccountSettingsIcon, viewMode: 'account' },
      { name: 'Team', icon: ViewIcons.TeamSettingsIcon, viewMode: 'team' },
      { name: 'Whitelabel', icon: ViewIcons.WhitelabelSettingsIcon, viewMode: 'whitelabel', blockedText: 'Requires team' },
      { name: 'Expert Profile', icon: ViewIcons.ExpertSettingsIcon, viewMode: 'expert' },
      { name: 'Referral', icon: ViewIcons.AffiliateReferralSettingsIcon, viewMode: 'referral' },
      { name: 'Integrations', icon: ViewIcons.IntegrationsSettingsIcon, viewMode: 'integrations' },
      { name: 'Plan', icon: ViewIcons.PlanSettingsIcon, viewMode: 'plan' },
      { name: 'Billing', icon: ViewIcons.BillingSettingsIcon, viewMode: 'billing', blockedText: 'Admin only' }
      // TODO: Notification settings will be hidden until we have notifications to send
      // { name: 'Notifications', icon: ViewIcons.NotificationsSettingsIcon, viewMode: 'notifications' }
    ]
    return {
      sidebarNavOptions,
      hasUnsavedChanges: false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getSettingsDrawerViewMode']),
    ...mapGetters('LensModule', ['getLenses']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'isTeamOwner']),
    ...mapGetters('RouterModule', ['getCurrentRouteId'])
  },
  mounted () {
    // Listen for the lens-settings-has-changes event
    this.$root.$on('lens-settings-has-changes', this.handleChangesUpdate)
  },
  beforeDestroy () {
    // Clean up the event listener
    this.$root.$off('lens-settings-has-changes', this.handleChangesUpdate)
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    ...mapMutations('RouterModule', ['SET_CURRENT_ROUTE_ID']),
    ...mapMutations('LensModule', ['SET_SHOW_LENS_CREATION_MODAL']),
    handleViewModeNavigation (viewMode) {
      this.SET_SETTINGS_DRAWER_VIEW_MODE(viewMode)
      this.SET_CURRENT_ROUTE_ID(null)
    },
    handleLensSettingsNavigation (lensId) {
      this.SET_SETTINGS_DRAWER_VIEW_MODE('lens')
      this.SET_CURRENT_ROUTE_ID(lensId)
    },
    // Prevent navigation for the below cases
    preventNav (option) {
      switch (option.viewMode) {
        // If user isn't admin
        case 'billing':
          return this.getTeam ? !this.isTeamOwner : false
        case 'whitelabel':
          return !this.getTeam
        default:
          return false
      }
    },
    handleLogout () {
      console.log('Logging out...')
      this.logoutUser()
    },
    handleCreateLens () {
      this.$router.push({ name: 'LensView' }).catch(err => {
        console.error('Error navigating to LensView:', err)
      })
      this.SET_SHOW_LENS_CREATION_MODAL(true)
      this.$emit('close')
    },
    // Add the getInitials method
    getInitials (name) {
      if (!name) return ''
      return name.split(' ').map(word => word[0]).join('').toUpperCase().substring(0, 2)
    },
    // Modified save method with console log for debugging
    handleSaveChanges () {
      console.log('Save button clicked in SettingsDrawer')

      // Get the current view mode to determine which component should handle the save
      const currentViewMode = this.getSettingsDrawerViewMode

      if (currentViewMode === 'lens') {
        console.log('Emitting save event for LensSettings')
        // Emit the event specifically for lens settings
        this.$root.$emit('lens-settings-save-clicked')
      } else {
        // Handle other view modes if needed
        console.log(`Emitting save event for ${currentViewMode}`)
        this.$root.$emit(`${currentViewMode}-settings-save-clicked`)
      }
    },
    // Add this new method to handle the changes event
    handleChangesUpdate (hasChanges) {
      console.log('Settings drawer received change update:', hasChanges)
      this.hasUnsavedChanges = hasChanges
    }
  }
}
</script>

<style scoped>
.content-grid {
  display: grid;
  grid-template-columns: 280px 1fr;
  width: 100%;
}
.logout-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: white;
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.logout-btn:hover {
  box-shadow: none;
  background-color: #FFF0F3;
}
.nav-btn {
  box-shadow: none;
  background-color: transparent;
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.nav-btn:hover:not([disabled]) {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.nav-btn.active, .nav-btn.active:hover {
  box-shadow: none;
  background-color: #F6F8FA; /* bg-neutral-25 */
}
.nav-btn:disabled{
  cursor: default !important;
}
.nav-btn:disabled > *{
  color: #a4abb8 !important;
}

.nav-btn:hover >>> .tooltip-text{
  opacity: 1;
}
</style>
