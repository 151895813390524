<template>
  <div>
    <div :class="{ 'h-5 mb-1': showLabelSpace }">
      <BaseText type="label" size="sm" class="text-text-muted">
        {{ label }}
      </BaseText>
    </div>
    <BaseText v-if="description" type="body" size="sm" class="text-text-normal mb-2.5 w-full">
      {{ description }}
    </BaseText>
    <div ref="dropdownSpacer" class="relative flex-grow flex h-8">
      <div ref="dropdownParent" class="dropdown-parent" :class="{'expanded': expanded}" v-on-clickaway="() => { expanded = false }"
      :style="{ maxWidth: maxWidth }">
        <button class="flex items-center gap-1 w-full px-2 py-1.5 flex-nowrap whitespace-nowrap rounded-lg focus:outline-none"
        :class="{[triggerClass]: triggerClass, 'pointer-events-none cursor-not-allowed bg-background-disabled text-text-disabled': disabled}" :style="{ minWidth: triggerMinWidth }" 
        @click="() => { if (!disabled) toggleDropdown() }" @focus="expanded = true" @mousedown.prevent>
          <slot name="icon" class="text-icon-normal mr-2 flex-shrink-0"/>
          <BaseText v-if="selectionPrefix.length" type="label" size="sm" class="text-text-muted mr-px ml-0.5">
            {{ selectionPrefix }}
          </BaseText>
          <!-- Currently Selected Item Label -->
          <BaseText v-if="selectedItem && Object.keys(selectedItem).length > 0" type="body" size="sm" 
          class="flex-grow text-text-muted truncate text-left mr-0.5" :class="{'ml-0.5': !selectionPrefix.length}">
            {{ selectedLabel(selectedItem) }}
          </BaseText>
          <!-- No selection placeholder -->
          <BaseText v-else type="body" size="sm" class="flex-grow text-neutral-500 placeholder truncate text-left">
            {{ placeholder }}
          </BaseText>
          <div v-if="!disabled" class="transform transition-transform flex-shrink-0" :style="{ transform: expanded ? 'scaleY(-1)' : '' }">
            <ChevronIcon class="text-icon-normal" />
          </div>
        </button>
        <!-- DROPDOWN CONTENT -->
        <div class="relative flex flex-col rounded-b-lg overflow-hidden" :class="{'transition-height': doAnimExpansion}"
        :style="{ height: `${dropdownHeight}px` }">
          <!-- Search Bar -->
          <div v-if="enableSearch" class="w-full flex items-center gap-1 px-2 py-2 bg-white border-t border-b border-neutral-50">
            <SearchIcon class="text-icon-normal flex-shrink-0" />
            <input v-model="searchQuery" ref="searchInput" class="search-input flex-grow" :placeholder="searchPlaceholder" />
          </div>
          <div ref="dropdownOptions" class="flex flex-col h-full gap-0.5 px-1 py-1 rounded-b-lg bg-white cursor-pointer overflow-y-scroll scrollbar-hide"
          :class="{'border-t border-neutral-50': !enableSearch}">
            <!-- Empty state -->
            <div v-if="!searchResults.length" class="px-1.5 py-0.5 whitespace-nowrap">
              <BaseText type="body" size="xs" class="text-text-subdued">
                {{ searchQuery.length ? 'No results found' : emptyStateText }}
              </BaseText>
            </div>
            <!-- Dropdown options -->
            <button
              v-for="(option, index) in searchResults"
              :key="`dropdown-option-${index}`"
              :ref="`dropdown-option-${index}`"
              class="group flex items-center justify-between rounded-md pl-1.5 pr-1 py-1 transition duration-100 hover:bg-neutral-25 whitespace-nowrap focus:outline-none"
              :class="{'bg-neutral-25': navIndex === index || selectedItem?.[optionKey] === option[optionKey], 'pointer-events-none': pointerEventsPaused}"
              @click="updateSelectedItem(option)"
              @mouseenter="() => { if (navIndex !== null) navIndex = null }"
            >
              <slot name="option-icon" :option="option" class="transition-colors text-neutral-400 group-hover:text-icon-normal mr-2 flex-shrink-0"/>
              <BaseText type="body" size="sm" class="text-text-muted flex-grow truncate text-left">
                {{ optionLabel(option) }}
              </BaseText>
              <div v-if="selectedItem && selectedItem[optionKey] === option[optionKey]" class="text-icon-normal">
                <CheckmarkIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ChevronIcon from './Icons/ChevronIcon.vue'
import CheckmarkIcon from './Icons/CheckmarkIcon.vue'
import SearchIcon from './Icons/SearchIcon.vue'

export default {
  name: 'BaseSingleDropdown',
  components: {
    ChevronIcon,
    CheckmarkIcon,
    SearchIcon
  },
  mixins: [clickaway],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    selectedObj: {
      type: [Object, String, Number],
      required: false,
      default: null
    },
    optionLabel: {
      type: Function,
      required: false,
      default: (option) => option.name || option.label || option
    },
    selectedLabel: {
      type: Function,
      required: false,
      default: (option) => option.name || option.label || option
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Search'
    },
    searchKey: {
      type: String,
      default: 'name'
    },
    selectionPrefix: {
      type: String,
      default: ''
    },
    optionKey: {
      type: String,
      required: false,
      default: 'id'
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    emptyStateText: {
      type: String,
      default: 'Nothing here'
    },
    showLabelSpace: {
      type: Boolean,
      default: false
    },
    triggerClass: {
      type: String,
      default: ''
    },
    sortOptions: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: 'auto'
    },
    maxWidth: {
      type: String,
      default: 'auto'
    },
    maxHeight: {
      type: Number,
      default: 203
    },
    maxWidth: {
      type: String,
      default: 'auto'
    },
    // If this is true, the dropdown will use the width of the parent element
    // Otherwise, the dropdown will size according to the content
    useParentWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchQuery: '',

      expanded: false,
      resizeObserver: null,
      pointerEventsPaused: false,
      navIndex: null,
      doAnimExpansion: true
    }
  },
  computed: {
    selectedItem: {
      get () {
        return this.selectedObj ?? null
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    dropdownOptions () {
      if (!this.sortOptions) return this.options
      return this.selectedItem ? [...this.options].sort((a, b) => a[this.optionKey] === this.selectedItem[this.optionKey] ? -1 : 1) : this.options
    },
    searchResults () {
      if (!this.enableSearch || !this.searchQuery.length || !this.dropdownOptions.length || !this.dropdownOptions[0][this.searchKey]) return this.dropdownOptions

      let searchItems = this.dropdownOptions.filter(item => item[this.searchKey].toLowerCase().includes(this.searchQuery.toLowerCase()))

      // Sort by the closeness of the match (closer = lower index)
      searchItems.sort((a, b) => {
        const ratioA = this.searchQuery.length / a[this.searchKey].length;
        const ratioB = this.searchQuery.length / b[this.searchKey].length;

        return ratioB - ratioA;
      });

      return searchItems;
    },
    triggerMinWidth () {
      return this.minWidth
    },
    dropdownHeight () {
      if (!this.expanded) return 0
      if (this.enableSearch && !this.searchResults.length) return 67
      if (!this.searchResults.length) return 30
      const numOptions = this.searchResults.length
      let computedHeight = numOptions * 30 - 2 + 8 // -2px for gap, +8px for padding
      if (this.enableSearch) computedHeight += 37
      return Math.min(computedHeight, this.maxHeight)
    }
  },
  watch: {
    expanded (expanded) {
      if (expanded) {
        window.addEventListener('keydown', this.handleKeyboardNavigation)
        if (this.enableSearch) {
          this.$nextTick(() => {
            this.$refs.searchInput.focus()
          })
        }
      } else {
        window.removeEventListener('keydown', this.handleKeyboardNavigation)
        this.navIndex = null
      }
    },
    searchQuery () {
      this.doAnimExpansion = false
      setTimeout(() => { this.enableExpansionTransition() }, 75)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.useParentWidth) {
        this.$refs.dropdownParent.style.right = '0'
      } else {
        this.reserveDropdownSpace()
        this.resizeObserver = new ResizeObserver(() => {
          this.reserveDropdownSpace()
        })
        this.resizeObserver.observe(this.$refs.dropdownParent)
      }
    })
  },
  beforeDestroy () {
    // Cleanup the keyboard listener
    window.removeEventListener('keydown', this.handleKeyboardNavigation)
    // Cleanup the resize observer
    if (this.resizeObserver && this.$refs.dropdownParent) {
      this.resizeObserver.unobserve(this.$refs.dropdownParent)
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    toggleDropdown () {
      this.expanded = !this.expanded
    },
    updateSelectedItem (option) {
      this.selectedItem = option
      this.expanded = false
    },
    reserveDropdownSpace () {
      const contentWidth = this.$refs.dropdownParent.offsetWidth
      this.$refs.dropdownSpacer.style.minWidth = `${contentWidth}px`
    },

    // ========= Keyboard Navigation =========

    handleKeyboardNavigation (event) {
      const validKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab']
      if (!validKeys.includes(event.key)) return
      if (event.key === 'Tab') {
        this.expanded = false
        return
      }
      event.preventDefault()
      if (!this.pointerEventsPaused && ['ArrowDown', 'ArrowUp'].includes(event.key)) {
        this.pauseItemPointerEvents()
      }
      switch (event.key) {
        case 'ArrowDown':
          this.navigateNext(); break
        case 'ArrowUp':
          this.navigatePrev(); break
        case 'Enter':
          this.selectNavItem(); break
        case 'Escape':
          this.expanded = false; break
      }
    },
    navigateNext () {
      if (this.navIndex === null || this.navIndex < this.searchResults.length - 1) {
        this.navIndex = this.navIndex === null ? 0 : this.navIndex + 1
        this.scrollToItem(`dropdown-option-${this.navIndex}`)
      }
    },
    navigatePrev () {
      if (this.navIndex === 0) {
        this.navIndex = null
      } else if (this.navIndex !== null) {
        this.navIndex--
        this.scrollToItem(`dropdown-option-${this.navIndex}`)
      }
    },
    selectNavItem () {
      if (this.navIndex === null) return
      this.updateSelectedItem(this.searchResults[this.navIndex])
    },
    scrollToItem (ref) {
      this.$nextTick(() => {
        const item = this.$refs[ref]?.[0]
        if (!item) return
        const itemRect = item.getBoundingClientRect()
        const container = this.$refs.dropdownOptions
        const containerRect = container.getBoundingClientRect()

        let scrollPosition = container.scrollTop
        const offset = 20
        const overflowTop = containerRect.top - itemRect.top
        const overflowBottom = itemRect.bottom - containerRect.bottom
        if (this.navIndex === 0) {
          scrollPosition = 0
        } else if (this.navIndex === this.searchResults.length - 1) {
          scrollPosition = container.scrollHeight
        } else if (overflowTop > 0) {
          scrollPosition = container.scrollTop - overflowTop - offset
        } else if (overflowBottom > 0) {
          scrollPosition = container.scrollTop + overflowBottom + offset
        }
        container.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      })
    },
    pauseItemPointerEvents () {
      this.pointerEventsPaused = true
      let initialMouseX, initialMouseY
      const trackMouseMovement = (event) => {
        if (!initialMouseX || !initialMouseY) {
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          return
        }
        const deltaX = Math.abs(event.clientX - initialMouseX)
        const deltaY = Math.abs(event.clientY - initialMouseY)
        if (deltaX >= 4 || deltaY >= 4) {
          this.pointerEventsPaused = false
          window.removeEventListener('mousemove', trackMouseMovement)
        }
      }
      window.addEventListener('mousemove', trackMouseMovement)
    },
    enableExpansionTransition: _.debounce(function () {
      this.doAnimExpansion = true
    }, 75)
  }
}
</script>

<style scoped>
.dropdown-parent {
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
  border-radius: 8px;
  background-color: white;
}
.dropdown-parent:hover, .dropdown-parent.expanded {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.transition-height {
  transition: height 100ms ease-in-out;
}

.search-input {
  /* Reset default styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.search-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.search-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.search-input:hover::placeholder {
  color: #303546;
}
.search-input:focus::placeholder {
  color: #A4ACB9;
}
</style>
