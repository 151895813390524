<template>
  <div class="flex flex-col w-full flex-1" style="padding-bottom: 50px;">
    <!-- Data Loading State -->
    <div v-if="loadingUserData" class="flex-grow w-full flex flex-col items-center justify-center">
      <BaseLoadingSpinnerCircle class="text-neutral-alpha-700" :width="28" :height="28" :duration="1" />
    </div>
    <!-- No Search Results State -->
    <div v-else-if="search.length && !getSearchedFolders?.length" style="max-height: 400px"
    class="flex-grow w-full flex flex-col items-center justify-center">
      <div class="flex flex-col items-center justify-center">
        <NoSearchResultsIcon/>
        <BaseText type="label" size="sm" class="text-white mt-4">
          No results found
        </BaseText>
        <BaseText type="body" size="sm" class="text-neutral-alpha-650 mt-1">
          Try refining your search
        </BaseText>
      </div>
    </div>
    <!-- Folders -->
    <div v-else-if="!loadingUserData" v-for="(folder, index) in getSearchedFolders" :key="`folder-${index}`" class="fade-in" @animationend="handlePostFolderFadeIn">
      <div class="relative">
        <button class="group w-full flex items-center gap-2 pl-1.5 py-1.5 pr-2.5 rounded-md h-8 flex-shrink-0 text-left select-none
        transition-colors duration-100 hover:bg-neutral-alpha-50 text-neutral-alpha-650 hover:text-neutral-alpha-800"
        :class="{'mt-2': index !== 0, 'text-neutral-alpha-800': openFolders.includes(folder.id), 'text-neutral-alpha-800 bg-neutral-alpha-50': expandedDropdown === folder.id}" 
        @click="toggleFolderOpen(folder.id)"
        :ref="`elm-${folder.id}`">
          <StarredFolderIcon v-if="folder.id === 'starred'" :is-active="getOpenFolders.includes(folder.id)"/>
          <FolderIcon v-else :is-active="getOpenFolders.includes(folder.id)"/>
          <BaseText type="body" size="sm" class="w-36 truncate">
            {{ 
              getIsPrivateMode && !['default', 'starred'].includes(folder.id)
                ? "•••••••••••"
                : folder.name || "Untitled Folder"
            }}
          </BaseText>
          <!-- Dropdown Opener Button -->
          <button v-if="!['default', 'starred'].includes(folder.id)" class="ml-auto flex items-center justify-center w-5 h-5 transition duration-100"
          :class="expandedDropdown === folder.id ? 'text-white' : 'text-neutral-alpha-600 hover:text-white opacity-0 group-hover:opacity-100'" 
          @click.stop.prevent="toggleDropdown(folder.id, 'folder', `elm-${folder.id}`)">
            <MoreIcon />
          </button>
        </button>
        <!-- Options Dropdown -->
        <transition name="folder-dropdown">
          <div v-if="expandedDropdown === folder.id" class="item-dropdown" :class="dropdownPosition" v-on-clickaway="() => { expandedDropdown = null }">
            <SidebarItemDropdown :items="getFolderDropdownItems(folder)" @close="expandedDropdown = null" />
          </div>
        </transition>
      </div>

      <!-- Default Boards Container -->
      <div class="pl-3">
        <BaseText v-if="getOpenFolders.includes(folder.id) && (folder.boards.length > 0 || (!folder.boards.length && !folder.briefs.length && !folder.spyders.length && !folder.lenses.length))"
        type="body" size="sm" class="text-white text-opacity-50 pt-2 pb-1 h-0 transition-heigth duration-100"
        :class="{'h-auto': getOpenFolders.includes(folder.id)}">
          Boards
        </BaseText>
        <!-- Board -->
        <div v-if="getOpenFolders.includes(folder.id) && (folder.boards.length > 0 || (!folder.boards.length && !folder.briefs.length && !folder.spyders.length && !folder.lenses.length))"
        class="border-l border-white border-opacity-10 pl-2" style="margin-left: 1px">
          <div v-for="(board, boardIndex) in folder.boards" :key="`${folder.id}-${board.id}-${boardIndex}`" class="relative">
            <router-link :to="{ name: 'BoardsDetailsView', params: { id: board.id, board: board } }" class="group"
            :ref="`elm-${folder.id}-${board.id}-${boardIndex}`">
              <div class="flex items-center mt-0.5 cursor-pointer h-7 pl-1 pr-2 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-25"
              :class="{'mt-0 pt-0': boardIndex === 0, 'bg-neutral-alpha-25': getIsBoardActive(board.id, folder.id === 'starred')}">
                <BoardIcon class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800 flex-shrink-0"
                :class="{'text-neutral-alpha-800': getIsBoardActive(board.id, folder.id === 'starred')}"/>
                <BaseText type="body" size="sm" class="align-middle ml-1 truncate select-none text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800"
                :class="{'text-neutral-alpha-800': getIsBoardActive(board.id, folder.id === 'starred')}">
                  <!-- Remove the '#' from the board name -->
                  {{ board.name.substring(1) }}
                </BaseText>
                <!-- Dropdown Opener Button -->
                <button class="ml-auto flex items-center justify-center w-5 h-5 transition-opacity duration-100 opacity-0 group-hover:opacity-100"
                :class="[
                  {'opacity-100': getIsBoardActive(board.id, folder.id === 'starred')}, 
                  expandedDropdown === `${folder.id === 'starred' ? 'starred-' : ''}${board.id}` ? 'text-white' : 'text-neutral-alpha-600 hover:text-white'
                ]" 
                @click.stop.prevent="toggleDropdown(`${folder.id === 'starred' ? 'starred-' : ''}${board.id}`, 'board', `elm-${folder.id}-${board.id}-${boardIndex}`)">
                  <MoreIcon />
                </button>
              </div>
            </router-link>
            <!-- Options Dropdown -->
            <transition name="board-dropdown">
              <div v-if="expandedDropdown === `${folder.id === 'starred' ? 'starred-' : ''}${board.id}`" 
              class="item-dropdown" :class="dropdownPosition" v-on-clickaway="() => { expandedDropdown = null }">
                <SidebarItemDropdown :items="getBoardDropdownItems(board)" @close="expandedDropdown = null" />
              </div>
            </transition>
          </div>
          <button class="w-full group flex items-center gap-1 p-1 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-25"
          @click="openCreateBoardModal(folder)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
            class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800">
              <path d="M10 6.25V10M10 10V13.75M10 10H6.25M10 10H13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="body" size="sm" class="text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800">
              Create Board
            </BaseText>
          </button>
        </div>
      </div>
      <!-- Folder Briefs -->
      <div class="pl-3" :class="getIsInspoMode ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''">
        <BaseText v-if="getOpenFolders.includes(folder.id) && folder.briefs.length > 0"
        type="body" size="sm" class="text-white text-opacity-50 pt-2 pb-1">
          Briefs
        </BaseText>
        <!-- Brief -->
        <div v-if="getOpenFolders.includes(folder.id) && folder.briefs.length > 0"
        class="border-l border-white border-opacity-10 pl-2" style="margin-left: 1px">
          <div v-for="(brief, briefIndex) in folder.briefs" :key="brief.id" class="relative">
            <router-link :to="{ name: 'EditBriefView', params: { id: brief.id } }" class="group cursor-pointer"
            :ref="`elm-${folder.id}-${brief.id}-${briefIndex}`">
              <div class="flex items-center mt-0.5 cursor-pointer pl-1 pr-2 rounded-md transition-colors duration-100 h-7 hover:bg-neutral-alpha-25"
              :class="{'mt-0 pt-0': briefIndex === 0, 'bg-neutral-alpha-25': getIsBriefActive(brief.id)}">
                <BriefIcon class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800 flex-shrink-0"
                :class="{'text-neutral-alpha-800': getIsBriefActive(brief.id)}"/>
                <BaseText type="body" size="sm" class="align-middle ml-1 truncate select-none text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800"
                :class="{'text-neutral-alpha-800': getIsBriefActive(brief.id)}">
                  {{ brief.name }}
                </BaseText>
                <!-- Dropdown Opener Button -->
                <button class="ml-auto flex items-center justify-center w-5 h-5 transition duration-100 opacity-0 group-hover:opacity-100"
                :class="[{'opacity-100': getIsBriefActive(brief.id)}, expandedDropdown === brief.id ? 'text-white' : 'text-neutral-alpha-600 hover:text-white']" 
                @click.stop.prevent="toggleDropdown(brief.id, 'brief', `elm-${folder.id}-${brief.id}-${briefIndex}`)">
                  <MoreIcon />
                </button>
              </div>
            </router-link>
            <!-- Options Dropdown -->
            <transition name="brief-dropdown">
              <div v-if="expandedDropdown === brief.id" class="item-dropdown" :class="dropdownPosition" v-on-clickaway="() => { expandedDropdown = null }">
                <SidebarItemDropdown :items="getBriefDropdownItems(brief)" @close="expandedDropdown = null" />
              </div>
            </transition>
          </div>
          <button class="w-full group flex items-center gap-1 p-1 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-25"
          @click="routeToCreateBrief(folder.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
            class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800">
              <path d="M10 6.25V10M10 10V13.75M10 10H6.25M10 10H13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="body" size="sm" class="text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800">
              Create Brief
            </BaseText>
          </button>
          <div />
        </div>
      </div>
      <!-- Folder Spyders -->
      <div class="pl-3">
        <BaseText v-if="getOpenFolders.includes(folder.id) && folder.spyders.length > 0"
        type="body" size="sm" class="text-white text-opacity-50 pt-2 pb-1 h-0 transition-heigth duration-100"
        :class="{'h-auto': getOpenFolders.includes(folder.id)}">
          Spyder
        </BaseText>
        <div v-if="getOpenFolders.includes(folder.id) && folder.spyders.length > 0"
        class="border-l border-white border-opacity-10 pl-2" style="margin-left: 1px">
          <div v-for="(spyder, brandIndex) in folder.spyders" :key="spyder.id" class="relative">
            <div :is=" spyder?.id && spyder?.name ? 'router-link' : 'div'" :to="{ name: 'SpyderView', params: { id: spyder?.id } }" class="group"
            :ref="`elm-${folder.id}-${spyder.id}-${brandIndex}`">
              <div class="flex items-center mt-0.5 cursor-pointer pl-1 pr-2 rounded-md transition-colors duration-100 h-7 hover:bg-neutral-alpha-25"
              :class="{'mt-0 pt-0': brandIndex === 0, 'bg-neutral-alpha-25': getIsSpyderActive(spyder?.id)}">
                <SpyderIcon class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800 flex-shrink-0"
                :class="{'text-neutral-alpha-800': getIsSpyderActive(spyder?.id)}"/>
                <BaseText type="body" size="sm" class="align-middle ml-1 truncate select-none text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800"
                :class="{'text-neutral-alpha-800': getIsSpyderActive(spyder?.id)}">
                  {{ spyder?.name || 'In Progress' }}
                </BaseText>
                <!-- Dropdown Opener Button -->
                <button class="ml-auto flex items-center justify-center w-5 h-5 transition duration-100 opacity-0 group-hover:opacity-100"
                :class="[{'opacity-100': getIsSpyderActive(spyder?.id)}, expandedDropdown === spyder.id ? 'text-white' : 'text-neutral-alpha-600 hover:text-white']" 
                @click.stop.prevent="toggleDropdown(spyder.id, 'spyder', `elm-${folder.id}-${spyder.id}-${brandIndex}`)">
                  <MoreIcon />
                </button>
              </div>
            </div>
            <!-- Options Dropdown -->
            <transition name="spyder-dropdown">
              <div v-if="expandedDropdown === spyder.id" class="item-dropdown" :class="dropdownPosition" v-on-clickaway="() => { expandedDropdown = null }">
                <SidebarItemDropdown :items="getSpyderDropdownItems(spyder)" @close="expandedDropdown = null" />
              </div>
            </transition>
          </div>
          <button class="w-full group flex items-center gap-1 p-1 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-25"
          @click="routeToAddSpyder(folder.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
            class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800">
              <path d="M10 6.25V10M10 10V13.75M10 10H6.25M10 10H13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="body" size="sm" class="text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800">
              Add Spyder
            </BaseText>
          </button>
        </div>
      </div>
      <!-- Folder Lenses -->
      <div class="pl-3">
        <BaseText v-if="getOpenFolders.includes(folder.id) && folder.lenses.length > 0"
        type="body" size="sm" class="text-white text-opacity-50 pt-2 pb-1 h-0 transition-heigth duration-100"
        :class="{'h-auto': getOpenFolders.includes(folder.id)}">
          Lens
        </BaseText>
        <div v-if="getOpenFolders.includes(folder.id) && folder.lenses.length > 0"
        class="border-l border-white border-opacity-10 pl-2" style="margin-left: 1px">
          <div v-for="(lens, lensIndex) in folder.lenses" :key="`${folder.id}-lens-${lensIndex}`" class="flex flex-col">
            <div :is="lens?.id && lens?.name ? 'router-link' : 'div'"
            :to="{ name: 'LensDashboardView', params: { lensId: lens?.id } }" class="group">
              <div class="flex items-center mt-0.5 cursor-pointer pl-1 pr-2 rounded-md transition-colors duration-100 h-7 hover:bg-neutral-alpha-25"
              :class="{'mt-0 pt-0': lensIndex === 0, 'bg-neutral-alpha-25': $route.name === 'LensDashboardView' && $route.params.lensId === lens?.id}">
                <LensIcon class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800 flex-shrink-0" 
                :class="{'text-neutral-alpha-800': $route.name === 'LensDashboardView' && $route.params.lensId === lens?.id}"/>
                <BaseText type="body" size="sm" class="align-middle ml-1 truncate select-none text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800"
                :class="{'text-neutral-alpha-800': $route.name === 'LensDashboardView' && $route.params.lensId === lens?.id}">
                  {{ lens?.name || 'Untitled Lens' }}
                </BaseText>
              </div>
            </div>
            <div v-if="lens.reports.length > 0" class="border-l border-white border-opacity-10 pl-2" style="margin-left: 13px">
              <div v-for="(report, reportIndex) in lens.reports" :key="`${lens.id}-report-${reportIndex}`" class="group">
                <div :is="report?.id && report?.name ? 'router-link' : 'div'"
                :to="{ name: 'LensReport', params: { lensId: lens?.id, reportId: report?.id } }">
                  <div class="flex items-center mt-0.5 cursor-pointer pl-1 pr-2 rounded-md transition-colors duration-100 h-7 hover:bg-neutral-alpha-25"
                  :class="{'mt-0 pt-0': reportIndex === 0, 'bg-neutral-alpha-25': $route.name === 'LensReport' && $route.params.reportId === report?.id}">
                    <ReportIcon class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800 flex-shrink-0"
                    :class="{'text-neutral-alpha-800': $route.name === 'LensReport' && $route.params.reportId === report?.id}" />
                    <BaseText type="body" size="sm" class="align-middle ml-1 truncate select-none text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800"
                    :class="{'text-neutral-alpha-800': $route.name === 'LensReport' && $route.params.reportId === report?.id}">
                      {{ report?.name || 'Untitled Report' }}
                    </BaseText>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <button class="w-full group flex items-center gap-1 p-1 rounded-md transition-colors duration-100 hover:bg-neutral-alpha-25"
            @click="openCreateLensModal()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
              class="text-neutral-alpha-500 transition-colors duration-100 group-hover:text-neutral-alpha-800">
                <path d="M10 6.25V10M10 10V13.75M10 10H6.25M10 10H13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
              <BaseText type="body" size="sm" class="text-neutral-alpha-600 transition-colors duration-100 group-hover:text-neutral-alpha-800">
                Create Lens
              </BaseText>
            </button>
        </div>
      </div>
    </div>

    <portal to="folder-modals">
      <FolderModal
        v-if="showFolderModal || folderModalOpen"
        @close="showFolderModal = false, $emit('update:folderModalOpen', false)"
      />
    </portal>
    <portal to="folder-modals">
      <ManageFolderModal
        v-if="showFolderManageModal"
      :folder="folderToManage"
        @close="showFolderManageModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ChangeFolderModal
        v-if="showMoveBoardModal"
        :item="boardToMove"
        item-type="board"
        @close="showMoveBoardModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ChangeFolderModal
        v-if="showMoveBriefModal"
        :item="briefToMove"
        item-type="brief"
        @close="showMoveBriefModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ChangeFolderModal
        v-if="showMoveSpyderModal"
        :item="spyderToMove"
        item-type="brand"
        @close="showMoveSpyderModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ConfirmDeleteModal
        v-if="showConfirmDeleteFolderModal"
        folder
        @confirm="deleteFolder()"
        @close="showConfirmDeleteFolderModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ConfirmDeleteModal
        v-if="showConfirmDeleteBoardModal"
        board
        @confirm="handleDeleteBoard()"
        @close="showConfirmDeleteBoardModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ConfirmDeleteModal
        v-if="showConfirmDeleteBriefModal"
        @confirm="handleDeleteBrief()"
        @close="showConfirmDeleteBriefModal = false"
      />
    </portal>
    <portal to="folder-modals">
      <ManageBoardModal
        v-if="showManageBoardModal"
        :board="boardToManage"
        :initial-folder="selectedFolder"
        @close="showManageBoardModal = false"
      />
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import firebase from '../../api/config/FirebaseConfig'
import ManageFolderModal from './ManageFolderModal'
import ChangeFolderModal from '../globals/ChangeFolderModal.vue'
import FolderModal from './FolderModal.vue'
import ClickOutside from 'vue-click-outside'
import ConfirmDeleteModal from '../globals/ConfirmDeleteModal.vue'
import FirebaseAPI from '../../api/firebase'
import ManageBoardModal from '../boards/ManageBoardModal.vue'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import SidebarItemDropdown from './SidebarItemDropdown.vue'

// Icons
import FolderIcon from '../globals/Icons/SidebarIcons/FolderIcon.vue'
import StarredFolderIcon from '../globals/Icons/SidebarIcons/StarredFolderIcon.vue'
import MoreIcon from '../globals/Icons/SidebarIcons/MoreIcon.vue'
import BoardIcon from '../globals/Icons/SidebarIcons/BoardIcon.vue'
import BriefIcon from '../globals/Icons/SidebarIcons/BriefIcon.vue'
import SpyderIcon from '../globals/Icons/SidebarIcons/SpyderIcon.vue'
import LensIcon from '../globals/Icons/SidebarIcons/LensIcon.vue'
import ReportIcon from '../globals/Icons/SidebarIcons/ReportIcon.vue'
import NoSearchResultsIcon from '../globals/Icons/SidebarIcons/NoSearchResultsIcon.vue'
import * as DropdownIcons from '../globals/Icons/SidebarIcons/dropdowns'

const DROPDOWN_HEIGHTS = {
  folder: 80,
  board: 226,
  brief: 153,
  spyder: 80
}

export default {
  name: 'Folders',
  mixins: [clickaway],
  components: {
    FolderModal,
    ManageFolderModal,
    ChangeFolderModal,
    ConfirmDeleteModal,
    ManageBoardModal,
    SidebarItemDropdown,
    FolderIcon,
    LensIcon,
    ReportIcon,
    BoardIcon,
    BriefIcon,
    SpyderIcon,
    StarredFolderIcon,
    NoSearchResultsIcon,
    MoreIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    isBetaUser: {
      type: Boolean,
      default: () => false
    },
    folderModalOpen: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      openFolders: [],
      folders: [],
      currentUser: {},
      remixiconUrl,
      expandedDropdown: null,
      dropdownPosition: 'bottom',
      loadingUserData: true,

      // Modal States
      showMoveBoardModal: false,
      showMoveBriefModal: false,
      showMoveSpyderModal: false,
      showMoveBriefToMoveModal: false,
      showFolderManageModal: false,
      showFolderModal: false,
      showManageBoardModal: false,
      showConfirmDeleteFolderModal: false,
      showConfirmDeleteBoardModal: false,
      showConfirmDeleteBriefModal: false,
      folderName: '',
      loadingDelete: false,
      loading: false,
      folderToManage: {},
      boardToMove: {},
      briefToMove: {},
      spyderToMove: {},
      folder: {},
      editBrief: {},
      shareBoardId: '',
      boardToManage: {},
      selectedFolder: {},
      loadingDeleteBoard: false,
      boardToDelete: {},
      boardToDeleteName: '',
      briefToDelete: {},
      briefToDeleteName: '',

      // route update triggers
      spyderLinkCounter: 0,
      briefsLinkCounter: 0
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getIsPrivateMode']),
    ...mapGetters('BoardsModule', ['getBoards', 'getFolders']),
    ...mapGetters('BriefsModule', ['getBriefs', 'getSidebarBriefs', 'getIsInspoMode']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'getUserName', 'getStarredBoards']),
    ...mapGetters('SpyderModule', ['getSpyderSubscriptions']),
    ...mapGetters('LensModule', ['getSidebarLenses']),
    getSearchedFolders () {
      if (!this.search.length) {
        return [
          {
            id: 'starred',
            name: 'Starred',
            boards: this.getStarredBoards,
            briefs: [],
            spyders: [],
            lenses: []
          },
          ...this.folders
        ]
      }
      return this.folders.map(folder => {
        const folderMatch = folder.name.toLowerCase().search(this.search.toLowerCase()) !== -1
        if (folderMatch) {
          return folder
        }
        const boardsMatch = folder.boards.filter(board =>
          board.name.toLowerCase().search(this.search.toLowerCase()) !== -1
        )

        const briefsMatch = folder.briefs.filter(brief =>
          brief.name.toLowerCase().search(this.search.toLowerCase()) !== -1
        )

        const spydersMatch = folder.spyders.filter(spyder =>
          spyder.name?.toLowerCase().search(this.search.toLowerCase()) !== -1
        )

        const lensesMatch = []
        for (const lens of folder.lenses) {
          const isLensMatch = lens.name?.toLowerCase().search(this.search.toLowerCase()) !== -1
          const reportMatch = []
          for (const report of lens.reports) {
            if (report.name?.toLowerCase().search(this.search.toLowerCase()) !== -1) {
              reportMatch.push(report)
            }
          }
          if (isLensMatch || reportMatch.length) lensesMatch.push({ ...lens, reports: reportMatch })
        }

        if (folderMatch || boardsMatch.length || briefsMatch.length || spydersMatch.length || lensesMatch.length) {
          return {
            ...folder,
            boards: boardsMatch,
            briefs: briefsMatch,
            spyders: spydersMatch,
            lenses: lensesMatch
          }
        }

        return null
      }).filter(Boolean)
    },
    getOpenFolders () {
      if (!this.search) {
        return this.openFolders
      } else {
        return this.getSearchedFolders.map(folder => folder.id)
      }
    }
  },
  watch: {
    getFolders () {
      this.getFolderItems()
    }
  },
  async mounted () {
    this.popupItem = this.$el
    this.SET_SPYDER_SUBSCRIPTIONS_LOADING(true)
    await this.fetchBoards()
    if (this.getUser.starredBoards) {
      this.SET_STARRED_BOARDS(
        this.getBoards.filter((board) =>
          this.getUser.starredBoards?.includes(board.id)
        )
      )
    }
    await this.fetchFolders()
    await this.fetchSidebarBriefs()
    await this.fetchSpyderSubscriptions()
    await this.fetchSidebarLenses()
    await this.getFolderItems()
    this.SET_SPYDER_SUBSCRIPTIONS_LOADING(false)
    this.loadingUserData = false
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions']),
    ...mapActions('BriefsModule', ['fetchBriefs', 'fetchSidebarBriefs']),
    ...mapActions('LensModule', ['fetchSidebarLenses']),
    ...mapMutations('AuthModule', ['SET_USER', 'SET_STARRED_BOARDS']),
    ...mapMutations('SpyderModule', ['SET_SPYDER_SUBSCRIPTIONS', 'SET_SPYDER_SUBSCRIPTIONS_LOADING']),
    ...mapMutations('BoardsModule', ['SET_FOLDERS']),
    ...mapMutations('LensModule', ['SET_SHOW_LENS_CREATION_MODAL']),
    openManageFolderModal (folder) {
      this.folderToManage = folder
      this.showFolderManageModal = true
    },
    openManageBoardModal (board) {
      this.showManageBoardModal = true
      this.boardToManage = board
    },
    openCreateBoardModal (folder) {
      this.boardToManage = {}
      this.selectedFolder = folder // Store the selected folder
      this.showManageBoardModal = true
      console.log('Selected Folder ', this.selectedFolder)
    },
    openCreateLensModal () {
      this.$router.push({ name: 'LensView' }).catch(err => {
        console.log('Error pushing to LensView', err)
      })

      this.SET_SHOW_LENS_CREATION_MODAL(true)
    },
    openMoveBoardModal (board, folder) {
      this.boardToMove = board
      this.folderToManage = folder

      this.showMoveBoardModal = true
    },
    openMoveSpyderModal (spyder, folder) {
      this.spyderToMove = spyder
      this.folderToManage = folder

      this.showMoveSpyderModal = true
    },
    openMoveBriefModal (brief, folder) {
      this.briefToMove = brief
      this.folderToManage = folder

      this.showMoveBriefModal = true
    },
    openFolderModal () {
      this.showFolderModal = true
    },
    openConfirmDeleteFolderModal (folder) {
      this.showConfirmDeleteFolderModal = true
      this.folder = folder
      this.folderName = folder.name
    },
    openConfirmDeleteBoardModal (board) {
      this.showConfirmDeleteBoardModal = true
      this.boardToDelete = board
      this.boardToDeleteName = board.name
    },
    openConfirmDeleteBriefModal (brief) {
      this.showConfirmDeleteBriefModal = true
      this.briefToDelete = brief
      this.briefToDeleteName = brief.name
    },
    toggleDefaultFolder () {
      this.defaultFolderOpen = !this.defaultFolderOpen
    },
    getFolderByPageId (pageId) {
      return this.getFolders.find(folder => folder?.spyderBrands?.includes(pageId))?.name || 'Default Folder'
    },
    async toggleStarredBoard (board) {
      // Track Event if board is starred
      window.analytics.track('Board Starred', {
        boardStarred: true
      })
      this.currentUser = this.getUser

      if (!this.currentUser.starredBoards) {
        this.currentUser.starredBoards = []
      }

      const index = this.currentUser.starredBoards.indexOf(board.id)
      if (index === -1) {
        this.currentUser.starredBoards.push(board.id)
      } else {
        this.currentUser.starredBoards.splice(index, 1)
      }

      this.SET_USER(this.currentUser)
      this.SET_STARRED_BOARDS(
        this.getBoards.filter((board) =>
          this.getUser.starredBoards?.includes(board.id)
        )
      )
      try {
        const db = firebase.firestore()
        await db.collection('auth-users').doc(this.currentUser.user_id).update({
          starredBoards: this.currentUser.starredBoards
        })
      } catch (err) {
        console.log(err)
      }
    },
    async removeSpyderBrand (brand) {
      const subscriptionId = this.getTeam?.id || this.getUser.user_id
      const adLibraryId = brand.subscription.pageId
      const removeSubscription = brand.subscription

      if (this.getFolderByPageId(adLibraryId) === 'Default Folder') {
        // the case for Default Folder
        const removePayload = removeSubscription.subscribers.filter(sub => sub !== subscriptionId)
        await FirebaseAPI.Spyder.update(removeSubscription.id, { subscribers: removePayload })
        this.SET_SPYDER_SUBSCRIPTIONS(this.getSpyderSubscriptions.filter(subscriptions => subscriptions.subscription.pageId !== adLibraryId))
        await this.fetchSpyderSubscriptions()
        this.fetchFolders()
        return
      }
      // updating subscriptions
      const removePayload = removeSubscription.subscribers.filter(sub => sub !== subscriptionId)
      await FirebaseAPI.Spyder.update(removeSubscription.id, { subscribers: removePayload })
      this.SET_SPYDER_SUBSCRIPTIONS(this.getSpyderSubscriptions.filter(subscriptions => subscriptions.subscription.pageId !== adLibraryId))

      // updating folders
      const updatedFolder = this.getFolders.find(folder => folder?.spyderBrands?.includes(brand.subscription.pageId))
      const updatePayload = updatedFolder.spyderBrands.filter(brandId => brandId !== brand.subscription.pageId)

      await FirebaseAPI.Folders.update(updatedFolder.id, { spyderBrands: updatePayload })
      this.SET_FOLDERS(this.getFolders.map(folder => folder.id === updatedFolder.id ? { ...folder, spyderBrands: updatePayload } : folder))
      await this.fetchSpyderSubscriptions()
      this.fetchFolders()
    },
    toggleFolderOpen (folderId) {
      if (this.openFolders.includes(folderId)) {
        this.openFolders = this.openFolders.filter((fId) => fId !== folderId)
      } else {
        this.openFolders.push(folderId)
      }

      localStorage.openFolders = JSON.stringify([...this.openFolders])
    },
    async copyBoardLink (boardId) {
      try {
        // Create if doesn't exist, otherwise return docID
        const sharedID = await FirebaseAPI.Boards.createShared(boardId)

        await this.$copyText(
          process.env.VUE_APP_BASE_URL +
        '/share/boards/' +
        encodeURIComponent(sharedID)
        )

        this.$showAlert({
          message: 'Copied share link to clipboard!',
          type: 'success'
        })
      } catch (err) {
        console.log(err)
      }
    },
    async copyBriefLink (briefId) {
      try {
        await this.$copyText(
          process.env.VUE_APP_BASE_URL +
        '/share/briefs/' +
        encodeURIComponent(briefId) +
        '?user=' +
        encodeURIComponent(this.getUserName)
        )

        this.$showAlert({
          message: 'Copied share link to clipboard!',
          type: 'success'
        })
      } catch (err) {
        console.log(err)
      }
    },
    async handleDeleteBoard () {
      this.loadingDeleteBoard = true
      this.showConfirmDeleteBoardModal = false

      try {
        await FirebaseAPI.Boards.remove(this.boardToDelete.id)

        this.$showAlert({
          message: 'Board deleted successfully',
          type: 'success'
        })

        // Make sure the side bar is up to date
        await this.fetchBoards()
        await this.fetchFolders()
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        if (this.$route.params.id === this.boardToDelete.id) {
          this.$router.push({ name: 'LibraryView' })
        }
        this.loadingDeleteBoard = false
      }
    },
    async handleDeleteBrief () {
      this.loadingDeleteBrief = true
      this.showConfirmDeleteBriefModal = false

      try {
        await FirebaseAPI.Briefs.remove(this.briefToDelete.id)

        this.$showAlert({
          message: 'Brief deleted successfully',
          type: 'success'
        })

        // Make sure the side bar is up to date
        await this.fetchSidebarBriefs()
        await this.fetchFolders()
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        if (this.$route.params.id === this.briefToDelete.id) {
          this.$router.push({ name: 'LibraryView' })
        }
        this.loadingDeleteBrief = false
      }
    },
    async archiveBrief (briefId) {
      FirebaseAPI.Briefs.update(briefId, { archived: true })
      await this.fetchSidebarBriefs()
      this.getFolderItems()
      this.$showAlert({
        message: 'Archived Selected Briefs',
        type: 'success'
      })
    },
    async deleteFolder () {
      this.showConfirmDeleteFolderModal = false
      // Make sure the folder being deleted isn't an experts folder
      if (this.getUser.expertProfile?.selectedFolder === this.folder.id) {
        this.$showAlert({
          message:
            'This is an experts folder, change your experts folder before deleting this',
          type: 'error'
        })

        return
      }

      this.loadingDelete = true

      await FirebaseAPI.Folders.remove(this.folder.id)

      // Refetch the boards and folders for the sidebar
      this.fetchFolders()

      // finished actions
      this.loadingDelete = false
      this.$emit('close')
    },
    async getFolderItems () {
      let folders = this.getFolders.map((folder) => ({
        ...folder,
        boards: [],
        briefs: [],
        spyders: [],
        lenses: []
      }))

      // Spread so we don't modify global state directly
      const boards = [...this.getBoards]
      const briefs = [...this.getSidebarBriefs]
      const spyders = [...this.getSpyderSubscriptions]
      const lenses = [...this.getSidebarLenses]

      const defaultFolder = {
        name: 'Default Folder',
        id: 'default',
        boards: [],
        briefs: [],
        spyders: [],
        lenses: []
      }

      // Loop through the boards to find their master folder
      while (boards.length) {
        const board = boards.pop()

        // Find the boards folder
        const folderIndex = folders.findIndex((folder) =>
          folder.boardIds.includes(board.id)
        )

        // Add board to folder
        if (folderIndex !== -1) {
          folders[folderIndex].boards.push(board)
        } else {
          // No folder - add it to the default folder
          defaultFolder.boards.push(board)
        }
      }

      // Loop through the briefs to find their master folder
      while (briefs.length) {
        const brief = briefs.pop()

        // Find the boards folder
        const folderIndex = folders.findIndex((folder) => {
          if (folder.briefIds) {
            return folder.briefIds.includes(brief.id)
          } else {
            return false
          }
        })

        // Add board to folder
        if (folderIndex !== -1) {
          folders[folderIndex].briefs.push(brief)
        } else {
          // No folder - add it to the default folder
          defaultFolder.briefs.push(brief)
        }
      }

      // Loop through the spyders to find their master folder
      while (spyders.length) {
        const spyder = spyders.pop()

        // Find the spyders folder
        const folderIndex = folders.findIndex((folder) =>
          folder.spyderBrands?.includes(spyder.subscription.pageId)
        )

        // Add spyders to folder
        if (folderIndex !== -1) {
          folders[folderIndex].spyders.push(spyder)
        } else {
          // No folder - add it to the default folder
          defaultFolder.spyders.push(spyder)
        }
      }

      // Loop through the lenses to find their master folder
      while (lenses.length) {
        const lens = lenses.pop()

        // Find the lenses folder
        const folderIndex = folders.findIndex((folder) =>
          folder.lenses?.includes(lens.id)
        )

        // Add lens to folder
        if (folderIndex !== -1) {
          folders[folderIndex].lenses.push(lens)
        } else {
          // No folder - add it to the default folder
          defaultFolder.lenses.push(lens)
        }
      }

      // Add default folder to the top of the folder list
      folders.unshift(defaultFolder)

      // Sort the folder items alphabetically
      folders = folders.map((folder) => {
        const copyFolder = { ...folder }

        copyFolder.briefs = copyFolder.briefs.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        )
        copyFolder.boards = copyFolder.boards.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        )
        copyFolder.lenses = copyFolder.lenses.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        )
        copyFolder.spyders = copyFolder.spyders.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        )

        return copyFolder
      })

      // Set folders
      this.folders = folders

      // Check local storage for open folders structure
      if (localStorage.openFolders) {
        this.openFolders = JSON.parse(localStorage.openFolders)
      }
    },
    routeToAddSpyder (folderId) {
      // Using a counter param ensures a route change even if the route is the same
      this.spyderLinkCounter++
      this.$router.push({
        name: 'SpyderBrandsView',
        query: { folderId, c: this.spyderLinkCounter }
      })
    },
    routeToCreateBrief (folderId) {
      this.briefsLinkCounter++
      this.$router.push({
        name: 'BriefsView',
        query: { folderId, c: this.briefsLinkCounter }
      })
    },
    getIsBoardActive (boardId, inStarred = false) {
      return (this.$route.name === 'BoardsDetailsView' && this.$route.params.id === boardId) || this.expandedDropdown === (inStarred ? `starred-${boardId}` : boardId)
    },
    getIsBriefActive (briefId) {
      return (this.$route.name === 'EditBriefView' && this.$route.params.id === briefId) || this.expandedDropdown === briefId
    },
    getIsSpyderActive (spyderId) {
      if (spyderId === undefined) return false
      return (this.$route.name === 'SpyderView' && this.$route.params.id === spyderId) || this.expandedDropdown === spyderId
    },
    handlePostFolderFadeIn (event) {
      // We need to remove the on mount fade in animation class so it doesn't break child elms with backdrop blur filters
      const folderElm = event.target
      if (folderElm.classList.contains('fade-in')) {
        folderElm.classList.remove('fade-in')
      }
    },
    toggleDropdown (id, type, elmRef) {
      if (this.expandedDropdown === id) {
        this.expandedDropdown = null
        return
      }

      // Compute the dropdown position (if there is not enough space below, we render above instead)
      const dropdownHeight = DROPDOWN_HEIGHTS[type]
      const sourcePosition = this.$refs[elmRef]?.[0]?.$el?.getBoundingClientRect()
      let position = 'bottom'
      if (sourcePosition) {
        const availableSpaceBelow = window.innerHeight - sourcePosition.top - sourcePosition.height - 56 // -56px for footer, padding, margin
        position = availableSpaceBelow < dropdownHeight ? 'top' : 'bottom'
      }

      this.dropdownPosition = position
      this.$nextTick(() => { this.expandedDropdown = id })
    },
    getFolderDropdownItems (folder) {
      return [
        { name: 'Edit', action: () => this.openManageFolderModal(folder), icon: DropdownIcons.EditItemIcon },
        { name: 'divider' },
        { name: 'Delete', action: () => this.openConfirmDeleteFolderModal(folder), icon: DropdownIcons.DeleteItemIcon }
      ]
    },
    getBoardDropdownItems (board, folder) {
      const isStarred = this.getUser?.starredBoards?.includes(board.id)
      return [
        { name: 'Share', action: () => this.copyBoardLink(board.id), icon: DropdownIcons.ShareItemIcon },
        { name: 'divider' },
        { name: 'Edit', action: () => this.openManageBoardModal(board), icon: DropdownIcons.EditItemIcon },
        { name: 'Move', action: () => this.openMoveBoardModal(board, folder), icon: DropdownIcons.MoveItemIcon },
        { name: 'divider' },
        {
          name: isStarred ? 'Un-Star' : 'Star',
          action: () => this.toggleStarBoard(board.id),
          icon: isStarred ? DropdownIcons.UnstarItemIcon : DropdownIcons.StarItemIcon
        },
        { name: 'Bulk Download (.ZIP)', action: () => this.openManageBoardModal(board), icon: DropdownIcons.BulkDownloadItemIcon },
        { name: 'divider' },
        { name: 'Delete', action: () => this.openConfirmDeleteBoardModal(board), icon: DropdownIcons.DeleteItemIcon }
      ]
    },
    getBriefDropdownItems (brief, folder) {
      return [
        { name: 'Share', action: () => this.copyBriefLink(brief.id), icon: DropdownIcons.ShareItemIcon },
        { name: 'divider' },
        { name: 'Move', action: () => this.openMoveBriefModal(brief, folder), icon: DropdownIcons.MoveItemIcon },
        { name: 'Archive', action: () => this.archiveBrief(brief.id), icon: DropdownIcons.ArchiveItemIcon },
        { name: 'divider' },
        { name: 'Delete', action: () => this.openConfirmDeleteBriefModal(brief), icon: DropdownIcons.DeleteItemIcon }
      ]
    },
    getSpyderDropdownItems (spyder, folder) {
      return [
        { name: 'Move', action: () => this.openMoveSpyderModal(spyder, folder), icon: DropdownIcons.MoveItemIcon },
        { name: 'divider' },
        { name: 'Delete', action: () => this.removeSpyderBrand(spyder), icon: DropdownIcons.DeleteItemIcon }
      ]
    }
  }
}
</script>

<style scoped>
.item-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
}
.item-dropdown.top {
  bottom: calc(100% + 4px);
}
.item-dropdown.bottom {
  top: calc(100% + 4px);
}

.v-enter-active, .v-leave-active {
  transition: opacity 75ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

/* Item Dropdown Animations */
.folder-dropdown-enter-active {
  animation: folderDropdownEnter 100ms ease-in-out forwards;
}
.folder-dropdown-leave-active {
  animation: folderDropdownLeave 100ms ease-in-out forwards;
}
@keyframes folderDropdownEnter {
  0% { opacity: 0; height: 20px; }
  100% { opacity: 1; height: 80px; }
}
@keyframes folderDropdownLeave {
  0% { opacity: 1; height: 80px; }
  100% { opacity: 0; height: 20px; }
}
.board-dropdown-enter-active {
  animation: boardDropdownEnter 100ms ease-in-out forwards;
}
.board-dropdown-leave-active {
  animation: boardDropdownLeave 100ms ease-in-out forwards;
}
@keyframes boardDropdownEnter {
  0% { opacity: 0; height: 20px; }
  100% { opacity: 1; height: 226px; }
}
@keyframes boardDropdownLeave {
  0% { opacity: 1; height: 226px; }
  100% { opacity: 0; height: 20px; }
}
.brief-dropdown-enter-active {
  animation: briefDropdownEnter 100ms ease-in-out forwards;
}
.brief-dropdown-leave-active {
  animation: briefDropdownLeave 100ms ease-in-out forwards;
}
@keyframes briefDropdownEnter {
  0% { opacity: 0; height: 20px; }
  100% { opacity: 1; height: 153px; }
}
@keyframes briefDropdownLeave {
  0% { opacity: 1; height: 153px; }
  100% { opacity: 0; height: 20px; }
}
.spyder-dropdown-enter-active {
  animation: spyderDropdownEnter 100ms ease-in-out forwards;
}
.spyder-dropdown-leave-active {
  animation: spyderDropdownLeave 100ms ease-in-out forwards;
}
@keyframes spyderDropdownEnter {
  0% { opacity: 0; height: 20px; }
  100% { opacity: 1; height: 80px; }
}
@keyframes spyderDropdownLeave {
  0% { opacity: 1; height: 80px; }
  100% { opacity: 0; height: 20px; }
}

.fade-in {
  animation: fadeIn 150ms ease-in-out forwards;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
